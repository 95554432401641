import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store"; // Import store and persistor from the correct location
import App from "./App";

if (typeof window !== 'undefined') {
    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(
        <HashRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App tab="home" />
                </PersistGate>
            </Provider>
        </HashRouter>
    );
}
