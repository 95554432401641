import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isScroll: true,
};

const windowScrollSlice = createSlice({
  name: "scrollTheWindow",
  initialState,
  reducers: {
    scrollWindow(state, action) {
      state.isScroll = action.payload;
    },
  },
});

export const { scrollWindow } = windowScrollSlice.actions;
export default windowScrollSlice.reducer;
