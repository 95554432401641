import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from 'react';
import React, { useState } from "react";
import { encryptStorage } from "./assets/js/encryptStorage";
import "./assets/css/app.css";
import './assets/css/mobile.css';
import "./assets/css/dashboard.css";
import loadingImg from './assets/image/loading2.gif';
import { Toaster } from "react-hot-toast";



const Login = lazy(() => import('./screens/auth/login/index'));
const SignUp = lazy(() => import('./screens/auth/signup/index'))
const PageNotFound = lazy(() => import('./screens/error_pages/404'))

//Tailwind
const TailwindDashboard = lazy(() => import('./screens/Dashboard'))
const TailwindUsers = lazy(() => import('./screens/Users'))
const TailwindUsersAdd = lazy(() => import('./screens/Users/Add'))
const TailwindUserEdit = lazy(() => import('./screens/Users/Edit'))
const TailwindSetupAccount = lazy(() => import('./screens/Users/Setup_account'))

// import TailwindSuppliers from './new_comp/Suppliers'
const TailwindSuppliers = lazy(() => import('./screens/Suppliers'))
const TailwindCompanies = lazy(() => import('./screens/Companies'))
const TailwindNotifications = lazy(() => import('./screens/Notifications'))
const TailwindSubscriptions = lazy(() => import('./screens/Subscriptions'))
const TailwindUpgrade = lazy(() => import('./screens/Subscriptions/Upgrade'))
const TailwindProceed = lazy(() => import('./screens/Subscriptions/Proceed'))

const TailwindDowngrade = lazy(() => import("./screens/Subscriptions/Downgrade"))
const TailwindActivation = lazy(() => import("./screens/Subscriptions/Activation/Wrapper"));
const TailwindCancellation = lazy(() => import("./screens/Subscriptions/Cancellation"));
const TailwindSuccess = lazy(() => import("./screens/Subscriptions/Success"));
const TailwindSuperAdminDashboard = lazy(() => import('./screens/SuperAdminDashboard'))

// Forgot Password
const TailwindForgotPassword = lazy(() => import("./screens/auth/ForgotPassword"));
const Tailwind_Reset_Password = lazy(() => import("./screens/auth/ForgotPassword/Reset_Password"));

// userChange Password
const TailwindChangePassword = lazy(() => import("./screens/auth/ChangePassword/User"));

// Profile
const TailwindAdminProfile = lazy(() => import("./screens/Profile/Admin"));
const TailwindUserProfile = lazy(() => import("./screens/Profile/User"));
// import TailwindNoMach from "./screens/NoMatch"

// Xero and QB auth Imports
const TailwindAuthLogin = lazy(() => import('./screens/auth/External_auth'));
const TailwindLayout = lazy(() => import('./screens/Layout'))

function App() {
    const [loading, setLoadingState] = useState(false);
    const [loadingImage, setLoadingImage] = useState(loadingImg);
    const handleLoading = async (data, loading_image = loadingImg) => {
        await setLoadingState(data);
        await setLoadingImage(loading_image);
    }
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />

            <Suspense
                fallback={
                    <div className={"loading"} title={"Please wait..."} style={{ display: loading ? "flex" : "none" }}>
                        <img className="loading-image" src={loadingImage} alt="Loading..." height="200" width="200" unselectable="on" style={{ borderRadius: "10px" }}/>
                    </div>
                }
            >
                <Routes>
                    <Route exact path="/*" element={<PageNotFound />} />
                    {/* <Route exact path="/*" element={<TailwindNoMach/>} /> */}
                    <Route exact path="/" element={<Login setLoading={handleLoading} />} />
                    <Route exact path="/login" element={<Login setLoading={handleLoading} />} />
                    <Route exact path="/auth/:type/:company_type/:email/:token/" element={<TailwindAuthLogin setLoading={handleLoading} />} />
                    <Route exact path="/auth/:type/:company_type/:email/:token/error/:error" element={<TailwindAuthLogin setLoading={handleLoading} />} />

                    <Route exact path="/login/error/:error_code" element={<Login setLoading={handleLoading} />} />


                    <Route exact path="/sign-up" element={<SignUp setLoading={handleLoading} />} />

                    <Route exact path="/forgot-password" element={<TailwindForgotPassword setLoading={handleLoading} />} />
                    <Route exact path="/reset-password/:email/:token" element={<Tailwind_Reset_Password setLoading={handleLoading} />} />
                    <Route exact path="/setup/account/:email/:token" element={<TailwindSetupAccount setLoading={handleLoading} />} />

                    {/* Tailwind Components  */}
                    <Route path="/" element={<TailwindLayout setLoading={handleLoading} />} >
                        {/* <Route path="/" element={/>} > */}

                        <Route exact path="/dashboard" element={<TailwindDashboard setLoading={handleLoading} />} />

                        {/* Admin Profile */}
                        <Route exact path="/new/profile" element={encryptStorage.getItem('user-type') === 'admin' ? <TailwindAdminProfile setLoading={handleLoading} /> : <TailwindUserProfile setLoading={handleLoading} />} />

                        {/* Change Password only for User */}
                        <Route exact path="/new/profile/change-password" element={encryptStorage.getItem('user-type') === 'admin' ? "" : <TailwindChangePassword setLoading={handleLoading} />} />


                        {/* Forgot Password */}


                        <Route exact path="/new/users" element={<TailwindUsers setLoading={handleLoading} />} />
                        <Route exact path="/new/users/add" element={<TailwindUsersAdd setLoading={handleLoading} />} />
                        <Route exact path="/new/users/edit" element={<TailwindUserEdit setLoading={handleLoading} />} />
                        <Route exact path="/new/suppliers" element={<TailwindSuppliers setLoading={handleLoading} />} />
                        <Route exact path="/new/companies" element={<TailwindCompanies setLoading={handleLoading} />} />
                        <Route exact path="/new/companies/:errorwhileconnect" element={<TailwindCompanies setLoading={handleLoading} />} />
                        <Route exact path="/new/notifications" element={<TailwindNotifications setLoading={handleLoading} />} />
                        <Route exact path="/new/subscriptions" element={<TailwindSubscriptions setLoading={handleLoading} />} />
                        <Route exact path="/new/upgrade" element={<TailwindUpgrade setLoading={handleLoading} />} />
                        <Route exact path="/new/proceed" element={<TailwindProceed setLoading={handleLoading} />} />
                        <Route exact path="/new/downgrade" element={<TailwindDowngrade setLoading={handleLoading} />} />
                        <Route exact path="/new/activation" element={<TailwindActivation setLoading={handleLoading} />} />
                        <Route exact path="/new/cancellation" element={<TailwindCancellation setLoading={handleLoading} />} />
                        <Route exact path="/new/success" element={<TailwindSuccess setLoading={handleLoading} />} />
                        <Route exact path="/new/admin/dashboard" element={<TailwindSuperAdminDashboard setLoading={handleLoading} />} />

                    </Route>

                </Routes>
            </Suspense>
        </>
    );
}

export default App;
