import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";


export const dateTimeSlice = createSlice({
  name: "dateTime",
  initialState: {
    inputData: { categories: [], accounts: [],contacts: [],dateTime: {startDate: moment().subtract(12, "months").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),endDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),type: "1M",dateType: "last12Months",month: moment().startOf('month')._d,fromTo: moment().startOf('month')._d,endTo: moment().endOf('month')._d},records: "expenses",paymentType: ["total"],},
  },
  reducers: {
    setCompanyId: (state, action) => {
      state.inputData.company = action.payload;
    },
    setReduxInputData: (state, action) => {
      state.inputData = action.payload;
    },
  },
});

export const { setReduxInputData } = dateTimeSlice.actions;


export default dateTimeSlice.reducer;
