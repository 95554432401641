import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const imageUrlSlice = createSlice({
  name: "storeUrl",
  initialState,
  reducers: {
    getUrl(state, action) {
      console.log(action.payload,'payload')
      return state=action.payload
    },
  },
});

export const { getUrl } = imageUrlSlice.actions;
export default imageUrlSlice.reducer;
