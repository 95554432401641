import { createSlice } from "@reduxjs/toolkit";

const initialState=[]



const accountListSlice = createSlice({
    name:"accountList",
    initialState,
    reducers:{
        addAccount(state,action){
            // if(state?.length>0){
                return state=action.payload
            // }
            // else{
                // state.push(action.payload)
            // }
        }
    }
})


export const {addAccount} = accountListSlice.actions

export default accountListSlice.reducer