import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  name: "",
};

const chartDownloadSlice = createSlice({
  name: "chartName",
  initialState,
  reducers: {
    changeName(state, action) {
      state.name = action.payload;
    },
  },
});

export const { changeName } = chartDownloadSlice.actions;
export default chartDownloadSlice.reducer;
