import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const imagePopupSlice = createSlice({
  name: "toogleImage",
  initialState,
  reducers: {
    toogleImage(state, action) {
      return (state = !state);
    },
  },
});

export const { toogleImage } = imagePopupSlice.actions;
export default imagePopupSlice.reducer;
