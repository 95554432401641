import { createSlice } from "@reduxjs/toolkit";
import { encryptStorage } from "../../assets/js/encryptStorage";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    isLogin: false,
  },
  reducers: {
    checkLoginStatus: (state, action) => {
      if (encryptStorage.getItem("token") == null) {
        toast.error("Token expired, Please sign in again.");
        navigate("/login");
        state.isLogin = false;
      } else {
        state.isLogin = true;
      }
      state.inputData = action.payload;
    },
  },
});

export const { checkLoginStatus } = userSlice.actions;

export default userSlice.reducer;
