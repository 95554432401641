import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isActive: false,
};

const activeUserSlice = createSlice({
  name: "changeActiveUser",
  initialState,
  reducers: {
    activeUser(state, action) {
      state.isActive = action.payload;
    },
  },
});

export const { activeUser } = activeUserSlice.actions;
export default activeUserSlice.reducer;
